<template>
  <div>
    <Loading v-if="loading" />
    <v-row>
      <v-col
        md="6"
        sm="6"
        cols="12"
      >
        <v-card>
          <v-row class="mt-0">
            <v-col
              md="4"
              sm="12"
              cols="12"
              class="align-self-start"
            ></v-col>
            <v-col
              md="4"
              sm="12"
              cols="12"
              class="align-self-start"
            >
              <v-img
                src="logos/wgl.png"
                height="auto"
              >
              </v-img>
            </v-col>
          </v-row>
          <v-card-text class="text-center">
            <p>Baltic Gaming League was founded in 2021 to give the Baltic states an stable & centralized community for esports.</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        md="6"
        sm="6"
        cols="12"
      >
        <v-card>
          <v-card-title>
            Why choose BGL?
          </v-card-title>
          <v-card-text>
            <p>This is the very beginning of starting off a new path for the Baltic States to unite and challenge each other, and grind for both yourself, and the awesome prizes each month - the better the Division, the better are the prizes. </p>
            <p>The new path for the Baltic States to unite and challenge each other, and grind for both yourself and the awesome prizes each month and week! We welcome all Baltic States players to compete, get better, and get noticed!</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        md="6"
        sm="6"
        cols="12"
      >
        <v-card>
          <v-card-title>
            Get in touch with us
          </v-card-title>
          <v-card-text>
            <template>
              <v-form v-model="valid" ref="form">
                <v-text-field
                  v-model="name"
                  name="name"
                  label="Name / Company Name"
                  outlined
                  :rules="[required]"
                  dense
                  placeholder="First Name / Company Name"
                ></v-text-field>

                <v-text-field
                  v-model="email"
                  label="Email"
                  type="email"
                  name="email"
                  outlined
                  dense
                  :rules="[required,emailValidator]"
                  placeholder="Email"
                ></v-text-field>
                <v-select
                  v-model="topic"
                  :items="['Partnership Offer', 'General inquiry', 'Press questions', 'BUG Report']"
                  label="Topic"
                  name="topic"
                  outlined
                ></v-select>
                <v-textarea
                  v-model="message"
                  name="message"
                  label="Message"
                  rows="2"
                  outlined
                  :rules="[required]"
                ></v-textarea>

                <vue-recaptcha sitekey="6LfJiHceAAAAAF4hfVih6Q59SVJ5VPtdP9zR1ixC" theme="dark" class="mb-4" @verify="setToken" @expired="setToken('')" @error="setToken('')"></vue-recaptcha>
                <v-btn color="primary" :disabled="!valid || token == ''" @click="sendEmail()">
                  Send it!
                </v-btn>
                <v-btn
                  type="reset"
                  outlined
                  class="mx-2"
                >
                  Reset fields
                </v-btn>
              </v-form>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        md="6"
        sm="6"
        cols="12"
      >
        <v-card>
          <v-card-title>
            In order to achieve our mission, we look forward to:
          </v-card-title>
          <v-card-text>
            <v-icon
              size="2rem"
              color="success"
              class="float-left mr-1 mt-1"
            >
              {{ icons.mdiCheckboxMarkedCircle }}
            </v-icon>
            <p>
              Provide a highly competitive environment and a moderated solo-queue for the players in the Baltics to socialize, practice and improve;
            </p>
            <v-icon
              size="2rem"
              color="success"
              class="float-left mr-1 mt-1"
            >
              {{ icons.mdiCheckboxMarkedCircle }}
            </v-icon>
            <p>
              Improve discovery, development and promotion of new talents within the Baltic CS:GO scene;
            </p>
            <v-icon
              size="2rem"
              color="success"
              class="float-left mr-1 mt-1"
            >
              {{ icons.mdiCheckboxMarkedCircle }}
            </v-icon>
            <p>
              Engage the players to interact more with each other, building a strong network and new storylines;
            </p>
            <v-icon
              size="2rem"
              color="success"
              class="float-left mr-1 mt-1"
            >
              {{ icons.mdiCheckboxMarkedCircle }}
            </v-icon>
            <p>
              Encourage live streaming content around competitive CS:GO, to teach/evangelise the community around playing competitively.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="2"
        sm="12"
        cols="12"
        class="align-self-start"
      >
        <a href="https://www.teamspeak.com/en/"><v-card>
          <v-img
            src="poster/ts.png"
            height="auto"
          >
          </v-img>
        </v-card></a>
      </v-col>
      <v-col
        md="2"
        sm="12"
        cols="12"
        class="align-self-start"
      >
        <a href="https://www.faceit.com/">
          <v-card>
            <v-img
              src="poster/faceit.png"
              height="auto"
            >
            </v-img>
          </v-card>
        </a>
      </v-col>
      <v-col
        md="2"
        sm="12"
        cols="12"
        class="align-self-start"
      >
        <v-card>
          <a href="https://forgamer.ee/">
            <v-img
              src="poster/fg-logo.png"
              height="auto"
            >
            </v-img>
          </a>
        </v-card>
      </v-col>
      <v-col
        md="2"
        sm="12"
        cols="12"
        class="align-self-start"
      >
        <a href="https://e-sports.ee/">
          <v-card>
            <v-img
              src="poster/easl.png"
              height="auto"
            >
            </v-img>
          </v-card>
        </a>
      </v-col>
      <v-col
        md="2"
        sm="12"
        cols="12"
        class="align-self-start"
      >
        <a href="https://twitter.com/LastWindGaming">
          <v-card>
            <v-img
              src="poster/lw.png"
              height="auto"
            >
            </v-img>
          </v-card>
        </a>
      </v-col>

      <v-col
        md="2"
        sm="12"
        cols="12"
        class="align-self-start"
      >
        <a href="https://biogaming.org/">
          <v-card>
            <v-img
              src="poster/bio.png"
              height="auto"
            >
            </v-img>
          </v-card>
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiStarOutline,
  mdiCheckCircleOutline,
  mdiAccountOutline,
  mdiDotsHorizontal,
  mdiTrophy,
  mdiCurrencyEur,
  mdiTwitch,
  mdiCheckboxMarkedCircle,
} from '@mdi/js'
import { required, emailValidator } from '@core/utils/validation'
import { ref } from '@vue/composition-api'
import axiosClient from '@/plugins/axios'
import SocialStatsCard from '@/views/components/about/SocialStatsCard.vue'
import Loading from '@/views/components/Loading.vue'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: {
    Loading,
    VueRecaptcha,
  },
  setup(props, { root }) {
    let token = ref('')
    const setToken = newToken => {
      token.value = newToken
    }
    let loading = ref(false)
    let valid = ref(false)
    let name = ref('')
    let email = ref('')
    let topic = ref('')
    let message = ref('')

    const sendEmail = () => {
      loading.value = true
      let params = {
        service_id: 'BGL',
        template_id: 'template_26570lu',
        user_id: 'user_fRhExo7XCckDQKLkrJV6p',
        template_params: {
          name: name.value,
          email: email.value,
          topic: topic.value,
          message: message.value,
          'g-recaptcha-response': token.value,
        },
      }
      axiosClient
        .post('https://api.emailjs.com/api/v1.0/email/send', params)
        .then(response => {
          loading.value = false
          root.vtoast.show('E-mail sent successfully!')
        })
        .catch(e => {
          loading.value = false
          root.vtoast.show('Something went wrong while sending the email!')
        })
    }

    return {
      token,
      setToken,
      loading,
      valid,
      name,
      email,
      topic,
      message,
      sendEmail,
      SocialStatsCard,
      emailValidator,
      required,
      icons: {
        mdiStarOutline,
        mdiCurrencyEur,
        mdiCheckCircleOutline,
        mdiAccountOutline,
        mdiDotsHorizontal,
        mdiTrophy,
        mdiTwitch,
        mdiCheckboxMarkedCircle,
      },
    }
  },
}
</script>
